<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <LayoutProductCollection
        v-model="filterSlug"
        :title="category?.name ?? '-'"
        :filters-response="filtersResponse"
        :refresh-filters="refreshFilters"
        type="category"
    >
        <!-- CATEGORY SUBCATEGORIES -->
        <div v-if="subCategories?.length" class="vut-image-menu">
            <ImageMenuCard
                v-for="subCategory in subCategories"
                :key="subCategory.id!"
                :to="subCategory.getUrl() ?? ''"
                :image-url="subCategory.thumbnailImageUrl ?? subCategory.imageUrl"
                :label="subCategory.name"
            />
        </div>
    </LayoutProductCollection>
</template>

<script lang="ts" setup>
import { PRODUCT_CARD_EMBEDS } from '../assets/ts/constants/embeds'

const { page } = useCurrentPage()

const route = useRoute()
const filterSlug = ref<string>(route.fullPath)

const [
    { item: category },
    { items: subCategories },
    { data: filtersResponse, refresh: refreshFilters },
] = await Promise.all([
    useCategoriesApiService()
        .onlyAttrs([
            CategoryModel.ATTR_NAME,
        ])
        .forId(page.value!.model!.id)
        .useGet(),
    useCategoriesApiService()
        .whereEquals(CategoryModel.ATTR_PARENT_ID, page.value!.model!.id)
        .sortByAsc(CategoryModel.ATTR_POSITION)
        .onlyAttrs([
            CategoryModel.ATTR_ID,
            CategoryModel.ATTR_NAME,
            CategoryModel.ATTR_DESCRIPTION,
        ])
        .embed([
            CategoryModel.EMBED_THUMBNAIL_IMAGE_URL,
            CategoryModel.EMBED_IMAGE_URL,
            CategoryModel.EMBED_URLS,
        ])
        .useGet(),
    useProductsFilterApiService()
        .embed([
            ...PRODUCT_CARD_EMBEDS,
        ])
        .usePost({ request_uri: filterSlug }, { watch: false }),
])

</script>

<style scoped lang="scss">
</style>
